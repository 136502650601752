import React from 'react'
import nl2br from 'react-nl2br'
import { useStaticQuery, graphql } from 'gatsby'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'
import Maxwidth from '../maxwidth'
import Logo from '../../images/logo.png'

const Top = styled.div`
  background: #000;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%, rgba(0,0,0,1) 85%, rgba(0,0,0,1) 100%);
`

const Bottom = styled.div`
  background-color: #000;
  color: #fff;
  padding-bottom: 2rem;
  @media(max-width: 1024px) {
    padding-bottom: 0;
    transform: translateY(-1px);
  }
`

const BottomFlex = styled.div`
  display: flex;
  max-width: calc(100vw - 2rem);
  margin-left: auto;
  margin-right: auto;
  column-gap: 2rem;
  @media (max-width: 1024px) {
    max-width: calc(90%);
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 680px) {
    flex-direction: column-reverse;
  }
  div {
    padding-top: 60px;
    padding-bottom: 60px;
    width: 100%;
    h1 {
      color: #fff;
      font-size: 50px;
      line-height: 60px;
      max-width: 400px;
      font-weight: 300;
      @media(max-width: 1024px) {
        font-size: 36px;
        line-height: 46px;
        font-weight: 300;
        max-width: 250px;
      }
      @media(max-width: 680px) {
        max-width: 100%;
      }
    }
    p {
      padding-top: 2rem;
      max-width: 80%;
      color: #fff;
    }
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(1400px - 2rem);
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

const Text = styled.div`
  width: 100%;
  max-width: 30%;
  padding-right: 2rem;
  @media(max-width: 1024px) {
    max-width: 80%;
    padding-right: 0;
    text-align: center;
    margin-bottom: 4rem;
  }
  @media(max-width: 400px) {
    max-width: 80%;
    padding-right: 0;
    text-align: center;
  }
  p {
    max-width: 300px;
    margin-bottom: 1rem;
    @media(max-width: 1024px) {
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Map = styled.div`
  width: 80%;
  height: 500px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid #BEBBBB;
  @media (max-width: 880px) {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  img {
    max-width: 100%;
    @media (max-width: 880px) {
      margin-bottom: 3rem;
      margin-top: 3rem;
    }
    .map-h2 {
      text-transform: uppercase;
      font-size: 1rem;
      padding: 20px;
      padding-left: 10px;
      text-align: center;
    }

    .google-map {
      width: 100%;
      height: 50vh;
    }

    .pin {
      display: flex;
      align-items: center;
      width: 180px;
      color: var(--pink);
    }

    .pin-icon {
      font-size: 4rem;
    }

    .pin-text {
      font-size: 1.3em;
    }

  @media screen and (min-width: 799px) {
    .google-map {
      height: 80vh;
    }

    .map-h2 {
      font-size: 1.3rem;
      font-weight: 400;
    }

    .pin {
      width: 15vw;
    }

    .pin-icon {
      font-size: 10vw;
    }
  }
}
`

const Auto = styled.div`
  @media (max-width: 880px) {
    width: 60%;
    p {
      max-width: 100% !important;
    }
  }
`

const Pictures = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 880px) {
    width: 40%;
  }
  @media (max-width: 680px) {
    display: none;
  }
`

const Picture1 = styled.div`
  position: absolute;
  border: 2px solid #fff;
  aspect-ratio: 3 / 4;
  top: 80px;
  left: 0px;
  max-width: 50%;
  background-image: url('${props => props.pic}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transform: rotate(-8deg);
  @media (max-width: 880px) {
    max-width: 50%;
  }
`

const Picture2 = styled.div`
  position: absolute;
  max-width: 50%;
  border: 2px solid #fff;
  aspect-ratio: 3 / 4;
  top: 150px;
  left: 200px;
  background-image: url('${props => props.pic}');
  background-repeat: no-;repeat;
  background-size: cover;
  background-position: center center;
  transform: rotate(8deg);
  @media (max-width: 880px) {
    max-width: 50%;
    top: 220px;
    left: 120px;
  }
`

const Picture3 = styled.div`
  display: none;
  @media (max-width: 680px) {
    display: block;
    border: 2px solid #fff;
    aspect-ratio: 3 / 4;
    background-image: url('${props => props.pic}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    transform: rotate(-6deg);
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`

const LogoContainer = styled.div`
width: 32px;
  height: 32px;
`

const Contact = () => {

  const data = useStaticQuery(graphql`
    query {
      allSanityContact {
        edges {
          node {
            address,
            phone,
            email,
            autographs_title,
            autographs_text,
            bottom_image {
              asset {
                url
              }
            }
            top_image {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `)

  const pics = data.allSanityContact.edges[0].node;

  const Marker = ({ text }) => <LogoContainer><img src={Logo} /></LogoContainer>;

  const defaultProps = {
    center: {
      lat: 51.518519,
      lng: -0.135399
    },
    zoom: 15
  };

  return (
    <>

      <Top>
        <Maxwidth nominheight>
          <Inner>
            <Text>
              <p><a href={`mailto:${pics.email}`}>{pics.email}</a>
                <br />Tel: {pics.phone}</p>
              <p>{nl2br(pics.address)}</p>
            </Text>
            <Map>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyDUb2WjzdIWE55C23tLYza7KgJTl1gfLw4" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}>
                <Marker lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
              </GoogleMapReact>
            </Map>
          </Inner>
        </Maxwidth>
      </Top>

      <Bottom>
        <Maxwidth nominheight>
          <BottomFlex>
            <Auto>
              <h1>{pics.autographs_title}</h1>
              <p>{nl2br(pics.autographs_text)}</p>
              <Picture3 pic={pics.top_image.asset.url}>&nbsp;</Picture3>
            </Auto>
            <Pictures>
              <Picture1 pic={pics.top_image.asset.url}>&nbsp;</Picture1>
              <Picture2 pic={pics.bottom_image.asset.url}>&nbsp;</Picture2>
            </Pictures>
          </BottomFlex>
        </Maxwidth>
      </Bottom>

    </>
  )
}

export default Contact
