import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Title from '../components/title'
import Contact from '../components/contact'
import PageSEO from '../components/page_seo'

const PageContact = () => {

  return (
    <>
      <PageSEO path="/contact" />
      <Title noborder>Contact</Title>
      <Contact />
    </>
  )
}

export default PageContact;
